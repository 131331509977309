.centred {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}

.btn {
  border: none;
  background-color: #037dd6;
  color: #fff;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}